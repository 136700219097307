<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="drawer = !drawer"
    />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title
      class="font-weight-light text-h5"
      v-text="routeName"
    />

    <v-spacer />

    <!-- <default-search class="hidden-sm-and-down" />

    <default-go-home />

    <default-notifications /> -->

    <h3 class="text-h5 px-1 py-2">{{ userInfo.full_name }}</h3>

    <default-account />
  </v-app-bar>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DefaultBar',

    components: {
      DefaultAccount: () => import(
        /* webpackChunkName: "default-account" */
        './widgets/Account'
      ),
      DefaultDrawerToggle: () => import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
      DefaultGoHome: () => import(
        /* webpackChunkName: "default-go-home" */
        './widgets/GoHome'
      ),
      DefaultNotifications: () => import(
        /* webpackChunkName: "default-notifications" */
        './widgets/Notifications'
      ),
      DefaultSearch: () => import(
        /* webpackChunkName: "default-search" */
        './widgets/Search'
      ),
    },

    computed: {
      ...sync('app', [
        'drawer',
        'mini',
      ]),
      ...mapGetters(['userInfo']),
      name: get('route/name'),
      routeName() {
        let value;

        switch (this.name) {
          case 'Users':
            value = 'Адміністрування';
            break;
          case 'UserProfile':
            value = 'Адміністрування';
            break;
          case 'Orders':
            value = 'Замовлення';
            break;
          case 'OrderProfile':
            value = 'Замовлення';
            break;
          case 'Icons':
            value = 'Замовлення';
            break;
          case 'Preorders':
            value = 'Передзамовлення';
            break;
          case 'PreorderProfile':
            value = 'Передзамовлення';
            break;
        }

        return value;
      }
    },
  }
</script>
